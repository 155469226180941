.B05NewsList {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}
.B05NewsList__title {
    margin-bottom: 40px;
    text-align: center;
}
.B05NewsList__more {
    display: flex; justify-content: center; align-items: center;
    padding-top: 30px;
}
.B05NewsList__slider {
    position: relative;
    margin: 0 -15px;
    .slick-list {
        overflow: visible;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        & > div {height: 100%;}
    }
    .slick-arrow {
        position: absolute; left: -60px; top: 50%; z-index: 2;
        display: flex; justify-content: center; align-items: center;
        width: 60px; height: 60px;
        border-radius: 50%; background-color: $cyan;
        color: transparent; font-size: 0px;
        transform: translateY(-50%); transition: 0.2s;
        &:hover {background-color: $blue-dark;}
        &::before {
            content: ""; position: relative;
            display: block; width: 18px; height: 18px;
            border: 0 solid $white; border-width: 4px 0 0 4px;
            transform: translateX(20%) rotate(-45deg);
        }
        &.slick-disabled {
            opacity: 0.3; background-color: $cyan;
            pointer-events: none;
        }
        @include tablet {left: -10px}
    }
    .slick-next {
        left: auto; right: -60px;
        &::before {transform: translateX(-20%) rotate(135deg);}
        @include tablet {right: -10px}
    }
}
.B05NewsList__slider-item {
    height: 100%;
}

.B05NewsCard {
    flex: 1; display: flex; flex-direction: column;
    padding: 0 15px; height: 100%; max-width: 500px;
    @include tablet {max-width: 100%;}
}
.B05NewsCard__image {
    position: relative;
    display: block; padding-top: 56%; width: 100%;
    border-radius: 30px 30px 0 0; overflow: hidden;

    img {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}
.B05NewsCard__content {
    flex: 1;
    padding: 25px 30px 30px;
    border-radius: 0 0 30px 30px; background-color: $grey-light;
}
.B05NewsCard__title {
    margin-bottom: 10px;
}
.B05NewsCard__infos {
    margin-bottom: 10px;
    color: $grey-dark;
}
.B05NewsCard__description {
    margin-bottom: 10px;
    color: $grey-dark;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.B05NewsCard__link {
    display: inline-flex; align-items: center;
    color: $blue-dark;
    cursor: pointer;
    svg {margin-right: 10px;}
    span {padding-bottom: 2px;}
    &:hover span {text-decoration: underline;}
}