.B01TextImage {
    margin: 50px 0;
    &--grey-light {
        margin: 0;
        background-color: $grey-light;
        
    }
    
}
.B01TextImage__container {
    display: flex; gap: 40px;
    @include tablet-sm {
        flex-direction: column; align-items: center;
    }
    .B01TextImage--grey-light & {
        padding: 50px 0;
    }
}
.B01TextImage__image {
    flex: 1; display: flex; justify-content: center; align-items: center;
    img{height: auto;}
    @include tablet-sm {
        padding-top: 40px;
        &-img {width: 60%;}
    }
}
.B01TextImage__text {
    flex: 1; padding: 65px 0;
    @include tablet-sm {
        width: 100%; padding: 0;
    }
}
.B01TextImage__text-title {
    margin-bottom: 20px;
}
.B01TextImage__text-wysiwyg {
    margin-bottom: 30px;
}
.B01TextImage__text-cta {

}