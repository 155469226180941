body div#hs-eu-cookie-confirmation {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 960px;
    border: 1px solid $cyan;
    border-radius: 10px;
    box-shadow: 0 0 50px rgba($cyan, .3);
}
body div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
    border-radius: 10px;
}