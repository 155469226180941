.S06Cta {
    &--mobile {display: none;}
    @include tablet {
        & ~ & {display: none;}
        .S05HubspotButton ~ & {display: none;}
        &--mobile {display: block;}
    }
}
.S06Cta__button {
    width: 100%; padding: 7px 15px 8px;
    font-family: 'Poppins', 'Lato';
    .SbBtn:nth-child(odd) &{
        border: 2px solid $blue-dark; background-color: $white; color: $blue-dark;
        &::before {background-color: $white;}
        &:hover,
        &:focus-visible{color: $white;}
    }
    // .SbBtn + .SbBtn & {
    //     border: 2px solid $blue-dark; background-color: $white; color: $blue-dark;
    //     &::before {background-color: $white;}
    // }
    // .SbBtn + .SbBtn + .SbBtn & {
    //     border: none; background-color: $blue-dark; color: $white;
    //     &::before {background-color: $blue-dark;}
    // }
    // .SbBtn + .SbBtn + .SbBtn + .SbBtn & {
    //     border: 2px solid $blue-dark; background-color: $white; color: $blue-dark;
    //     &::before {background-color: $white;}
    // }
    // .SbBtn + .SbBtn + .SbBtn + .SbBtn + .SbBtn & {
    //     border: none; background-color: $blue-dark; color: $white;
    //     &::before {background-color: $blue-dark;}
    // }
}