.B06JobsList {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
    overflow: hidden;
}
.B06JobsList__title {
    margin-bottom: 30px;
    text-align: center;
}
.B06JobsList__subtitle {
    margin-bottom: 40px;
    line-height: 24px; text-align: center;
    p {line-height: 24px;}
}
.B06JobsList__more {
    display: flex; justify-content: center; align-items: center;
    padding-top: 30px;
}
.B06JobsList__slider {
    position: relative;
    margin: 0 -15px;
    .slick-list {
        overflow: visible;
        //.layout--sidebar & {overflow: hidden;}
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: auto;
        & > div {height: 100%;}
    }
    .slick-arrow {
        position: absolute; left: -60px; top: 50%; z-index: 2;
        display: flex; justify-content: center; align-items: center;
        width: 60px; height: 60px;
        border-radius: 50%; background-color: $cyan;
        color: transparent; font-size: 0px;
        transform: translateY(-50%); transition: 0.2s;
        &:hover {background-color: $blue-dark;}
        &::before {
            content: ""; position: relative;
            display: block; width: 18px; height: 18px;
            border: 0 solid $white; border-width: 4px 0 0 4px;
            transform: translateX(20%) rotate(-45deg);
        }
        &.slick-disabled {
            opacity: 0.3; background-color: $cyan;
            pointer-events: none;
        }
        .layout--sidebar & {left: -30px}
        @include tablet {
            left: -10px;
            .layout--sidebar & {left: -10px}
        }
    }
    .slick-next {
        left: auto; right: -60px;
        &::before {transform: translateX(-20%) rotate(135deg);}
        .layout--sidebar & {left: auto; right: -30px}
        @include tablet {
            left: auto; right: -10px;
            .layout--sidebar & {left: auto; right: -10px}
        }
    }
}
.B06JobsList__slider-item {
    height: 100%;
}

.B06JobsCard {
    flex: 1; display: flex; flex-direction: column;
    padding: 0 15px; height: 100%; max-width: 500px;
    @include tablet {max-width: 100%;}
}
.B06JobsCard__image {
    position: relative;
    display: block; padding-top: 56%; width: 100%;
    border-radius: 30px 30px 0 0; overflow: hidden;

    img {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}
.B06JobsCard__content {
    flex: 1;
    padding: 25px 30px 30px;
    border-radius: 0 0 30px 30px; background-color: $grey-light;
}
.B06JobsCard__title {
    margin-bottom: 15px;
}
.B06JobsCard__infos {
    margin-bottom: 15px;
    color: $grey-medium-light;
}
.B06JobsCard__description {
    margin-bottom: 15px;
    color: $grey-dark;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.B06JobsCard__link {
    display: inline-flex; align-items: center;
    // color: $cyan;
    cursor: pointer;
    svg {margin-right: 10px;}
    span {padding-bottom: 2px;}
    &:hover span {text-decoration: underline;}
}



.B06JobsMore {
    flex: 1; display: flex; flex-direction: column;
    padding: 0 15px; height: 100%; max-width: 500px;
    @include tablet {max-width: 100%;}
}
.B06JobsMore__content {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    height: 100%; padding: 20px;
    background-color: $blue-dark; border-radius: 30px;
    text-align: center;
}
.B06JobsMore__title {
    color: $white;
}
.B06JobsMore__button {
    margin-top: 30px;
    color: $white;
}