.comments {
    padding: 50px 0 70px;
}
.comments__title {
    margin-bottom: 20px;
}
.comments__list {

}

.comment {
    
}
.comment__wrapper {
    display: flex; gap: 10px;
    margin-bottom: 20px;
}

.comment__author {
    flex-shrink: 0; display: flex; flex-direction: column;
    text-align: left;
    .comment__children & {
        align-items: flex-end; order: 2;
        text-align: right;
        
    }
}
.comment__author-picture {
    display: flex; justify-content: center; align-items: center;
    width: 40px; height: 40px; margin-bottom: 4px;
    background-color: $gold; border-radius: 50%;
    text-align: center; font-size: 22px; font-weight: 700; color: $white; text-transform: uppercase;
    .comment__children & {
        background-color: $blue-dark;
    }
}
.comment__author-name {
    font-size: 12px; color: $grey-medium-dark; font-weight: 700;
}
.comment__author-date {
    margin: 0;
    font-size: 12px; color: $grey-medium-dark;
}

.comment__content {
    position: relative;
    flex-grow: 1; order: 1;
    padding: 20px 60px 20px 25px;
    background-color: $grey-light; border-radius: 5px;
    &::before {
        content: ""; position: absolute; left: -7px; top: 12px;
        width: 15px; height: 15px;
        background-color: $grey-light;
        transform: rotate(-45deg) skew(7deg, 7deg);
    }
    .comment__children & {
        background-color: $gold-light;
        &::before {
            left: auto; right: -7px;
            background-color: $gold-light;
        }
    }
}
.comment__content-text {
    flex-grow: 1;
    color: $grey-medium-dark;
}
.comment__content-reply {
    position: absolute; right: 14px; top: 50%; z-index: 1;
    display: flex; justify-content: center; align-items: center;
    width: 32px; height: 32px; padding-bottom: 2px;
    border: 1px solid $cyan; border-radius: 50%;
    color: $cyan;
    transform: translateY(-50%); cursor: pointer; transition: 0.2s;
    .icon {
        display: block;
        width: 16px; height: 16px;
        transition: 0.2s;
    }
    &:hover {
        background-color: $cyan;
        .icon {color: $white;}
    }
}

.comment__children {

}

.comments__form {

}
.comments__form-title {
    margin-bottom: 5px;
}
.comments__form-subtitle {
    margin-bottom: 20px; color: $grey-dark;
    .link {
        color: $cyan; cursor: pointer;
        &:hover {text-decoration: underline;}
    }
}

.comment__replyForm {
    display: none;
    margin-bottom: 20px; padding-bottom: 20px;
    border-bottom: 1px solid #d5d5d5;
}
.comment__replyForm-title {
    margin-bottom: 10px;
}

.commentForm {
    display: flex; flex-wrap: wrap; justify-content: flex-end; gap: 20px;

    input, textarea {
        flex: 1;
        padding: 8px 10px; width: 100%; min-height: 50px; min-width: 200px;
        border: 1px solid #d5d5d5; border-radius: 5px;
        background-color: $white;
        font-size: 14px;
        &::placeholder {color: $grey-medium-dark; font-style: normal;}
    }
    textarea {flex-shrink: 0; min-width: 100%;}
    
}
.commentForm__submit {
    flex: 1; min-width: 230px; max-width: 230px;
}