
/* Colors */
$white: #ffffff;
$grey-light: #f5f7fa;
$grey-medium-light: #aaaaaa;
$grey: #868686;
$grey-medium-dark: #5F5F5F;
$grey-dark: #434343;
$black: #000000;

$blue: #11379e;
$blue-dark: #102248;
$blue-dark-2: #001479;
$cyan: #0cc1e3;
$cyan-light: #20D4F3;
$gold-light: #f8f5ea;
$gold: #d2af4b;

/* Dimensions */
$innerWidth: 1300px;
$innerSmallWidth: 940px;
$innerPadding: 80px;
$innerPadding-tablet: 40px;
$innerPadding-mobile: 20px;