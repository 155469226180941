.layout {
    position: relative;
    padding: 1px 0; margin: -1px 0;
}
.layout__sidebar {
    position: absolute; z-index: 2;
    width: 100%; padding-top: 30px;
    pointer-events: none;
    transition: 0.2s;
    &.is-fixed {
        position: fixed; left: 0; top: 70px;
        .admin-bar & {padding-top: 62px;}
    }
    &.is-bottom {
        position: absolute; top: auto; bottom: 50px;
    }
    @include tablet {z-index: 11;}
}
.sidebar__item {
    margin-bottom: 10px;
    &:last-child {margin: 0;}
}

.section {
    overflow: hidden;
    &:first-child {margin-top: 0;}
}
.inner {
    max-width: $innerWidth; 
    padding: 0 $innerPadding; 
    margin: 0 auto;

    @include tablet {
        padding: 0 $innerPadding-tablet; 
    }
    @include mobile {
        padding: 0 $innerPadding-mobile; 
    }
}
.inner-sm {
    max-width: $innerSmallWidth; 
    margin: 0 auto;
}
.section__inner {
    max-width: $innerWidth; 
    padding: 0 $innerPadding; 
    margin: 0 auto;

    @include tablet {
        padding: 0 $innerPadding-tablet; 
    }
    @include mobile {
        padding: 0 $innerPadding-mobile; 
    }
    .layout--sidebar & {
        display: flex;
        padding: 0 calc($innerPadding - 30px);

        @include tablet {
            padding: 0 $innerPadding-tablet; 
        }
        @include mobile {
            padding: 0 $innerPadding-mobile; 
        }
    }
}
.section__sidebar {
    display: none; flex-shrink: 0;
    position: relative; z-index: 1;
    width: 25%; padding: 0 30px; 
    background-color: $white;
    pointer-events: all;

    &::before {
        content: ""; position: absolute; right: 100%; top: 0; z-index: 1;
        display: block; width: calc((100vw - $innerWidth + $innerPadding + 6px) /2); height: 100%; min-width: 80px;
        background-color: $white;
    }
    .layout--sidebar & {
        display: block;
        @include tablet {
            display: none;
        }
    }
    .layout__sidebar &::before {content: none;}
    .layout__sidebar & {
        max-height: calc(100vh - 132px);
        overflow: auto;
    }
    @include tablet {
        .layout--sidebar .layout__sidebar & {
            position: fixed; left: 0; bottom: 0;
            display: block; width: 100%;
            padding: 15px 15px 10px; overflow: hidden;
            box-shadow: 0 0 26px rgba(16, 34, 72, 0.19); border-radius: 30px 30px 0 0;
        }
    }
}
.section__content {
    flex: 1;
    width: 100%; min-width: 0;
    .layout--sidebar & {
        padding: 0 30px;
        @include tablet {
            padding: 0; 
        }
    }
}