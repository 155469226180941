// Homepage Hero
.homepageHero {
    position: relative;
    background-color: $grey-light;
    overflow: hidden;
}
.homepageHero__bg {
    position: absolute; top: 0; right: -20px;
    width: 60%; height: 100%;
    background-position: top right; background-size: cover;
    opacity: 0.11;
    @include tablet-sm {
        top: auto; right: 0; bottom: 0; left: 0;
        width: 100%; height: 50%;
    }
}
.homepageHero__container {
    display: flex; align-items: center;
    height: 360px;
    @include tablet-sm {
        flex-direction: column;
        height: auto;
    }
}
.homepageHero__content {
    width: 56%;
    @include tablet-sm {width: 100%; margin-bottom: 35px;}
}
.homepageHero__title {
    margin: 25px 0;
}
.homepageHero__cta {
    @include tablet-sm {width: 100%;}
}

.homepageHero__illustration {
    position: relative;
    flex-grow: 1; display: flex; align-items: flex-end;
    height: 100%; margin-left: -7%;
    @include tablet-sm {
        flex-direction: column; align-items: center;
    }
}
.homepageHero__illustration-image {
    display: block; max-width: 60%;
    @include tablet-sm {max-width: 80%;}
}
.homepageHero__illustration-picture {
    display: block;
    img {width: 360px; height: auto;}
}
.homepageHero__illustration-image-picture{}


.homepageHero__review {
    position: absolute; right: 0; top: 70px;
    width: 45%; max-width: 265px; padding-left: 20px; margin-bottom: 20px;
    @include tablet-sm {position: relative; width: 100%; left: auto; top: auto;}
    font-family: 'Poppins', 'Lato';
}
.homepageHero__review-icon {
    position: absolute; left: 0; top: 0;
    width: 10px; height: 100%;
}
.homepageHero__review-note {
    margin-bottom: 5px;
    color: $gold;
    .icon {width: 20px; height: 20px;}
}
.homepageHero__review-text {
    margin-bottom: 5px;
    font-size: 16px; line-height: 22px; font-weight: 600; color: $blue-dark;
}
.homepageHero__review-subtext {
    font-size: 11px; color: #868686;
}

// Page Hero
.pageHero {
    text-align: center;
    background-color: #152245;
}
.pageHero__container {
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    min-height: 170px; padding: 40px 0;
}
.pageHero__title {
    margin-bottom: 5px;
    color: $white
}
.pageHero__subtitle {
    margin-top: -15px;
    margin-bottom: 10px;
    color: $white
}
.pageHero__breadcrumb {

}


// Single Hero
.singleHero {

    .inner {
        max-width: 1220px; padding: 0;
    }
}
.singleHero__image {
    display: block; width: 100%; height: 330px;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
    img {
        width: 100%; height: 100%;
        object-fit: cover;
    }
}

.singleLede {
    overflow: visible;
}
.singleLede__container {
    position: relative; z-index: 1;
    padding: 35px 30px; margin-top: -75px;;
    background-color: $grey-light; border-radius: 30px;
}
.singleLede__title {
    margin-bottom: 23px;
    text-align: center;
}
.singleLede__text {
    margin-top: 30px;
}
.singleLede .breadcrumb__item a{
    color: $grey-dark;
}