.B13Cta {
    margin: 30px 0;
    &:first-child {padding-top: 30px;}
}
.B13Cta__wrapper {
    width: 100%; margin: 0 auto; padding: 0 100px;
    @include tablet {
        max-width: 650px; padding: 0;
    }
}
.B13Cta__container {
    display: flex; justify-content: center; align-items: center;
    position: relative; width: 100%;
    &::before {
        content: ""; position: absolute; left: 0; top: 50%;
        width: 100%; height: 2px;
        background-color: $blue-dark;
        transform: translateY(-50%);
    }
}
.B13Cta__button {
    position: relative; z-index: 2;
    min-width: 220px;
}