.B10Text {
    margin: 50px 0;
    & + & {margin-top: -50px;}
    &:first-child {padding-top: 50px;}
}
.B10Text__container {
    width: 100%;
}
.B10Text__title {
    margin: 30px 0 20px;
}
.B10Text__subtitle {
    margin: 20px 0;
}
.B10Text__content {

}
blockquote.B10Text__citation, .B10Text__citation {
    position: relative;
    float: right;
    padding: 60px 45px; width: 260px; margin-left: 30px; margin-bottom: 20px;
    background-color: $blue-dark; border-radius: 30px;
    color: $white; font-weight: 700; font-family: 'Poppins', sans-serif;
    svg {
        position: absolute; left: 25px; top: 35px;
        width: 40px; height: 40px;
    }
    span {margin-left: 30px;}
    @include tablet-sm {
        margin: 0 0 20px; width: 100%; padding: 50px 45px;
    }
    &::before, &::after {content: none;}
}
.B10Text__image {
    float: right;
    width: 260px; margin-left: 30px; margin-bottom: 20px;
    border-radius: 30px;
    overflow: hidden;
    @include tablet-sm {
        margin: 0 0 20px;
    }
}
.B10Text__wysiwyg {

    @include tablet-sm {
        display: flex; flex-direction: column;
        blockquote + h2, blockquote + h3, blockquote + h4 {
            order: -1;
        }
        picture + h2, picture + h3, picture + h4 {
            order: -1;
        }
    }
}