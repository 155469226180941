.B20AnalogQuestions {
    margin: 40px 0;
    &:first-child {padding-top: 40px;}
}
.B20AnalogQuestions__title {
    margin-bottom: 8px;
}
.B20AnalogQuestions__content {

    h3 {
        padding: 5px 0;
    }
}