/* 
EXEMPLE :
<div class="form__group">
    <input name="input_1" placeholder="" type="text" class="form__group__input js-form-input" id="nom-1" required="" aria-required="true" value="">
    <label class="form__group__label" for="nom-1">
        <span class="form__group__label-text">Nom*</span>
    </label>
    <i class="form__group__bar"></i>
</div>
*/

/***** MATERIAL FORM *****/
.materialForm{}
.form__radio,
.form__group {
    position: relative;
    margin-top: 2.25rem; margin-bottom: 2.25rem;
    font-size: 15px; // uniformisation pour gform
}
.form__group input {
    height: 1.9rem;
}
.postalCode .form__group input {height: 50px;}
.form__group textarea {resize: none;}
.form__group select {
    width: 100%; height: 2rem;
    font-size: 1rem; line-height: 1.6;
    background: none; border: none; box-shadow: none;
}
.form__group .form__group__label {
    z-index: 1; position: absolute; top: -.5rem;
    // pointer-events: none; // whyyyyyyyyyyyyyyyyyyyyyyyyy ?
    padding-left: 0.125rem;
    font-size: 1em; color: #979797;
    transition: all 0.28s ease;
}
.postalCode .form__group .form__group__label{top: 1.5rem;}
.form__group .form__group__bar {
    position: relative;
    display: block;
    border-bottom: 0.0625rem solid #979797;
}
.form__group .form__group__bar::before {
    z-index: 2;
    content: '';
    width: 0; height: 0.125rem;
    position: absolute; bottom: -0.0625rem; left: 50%;
    background: #003259;
    transition: left 0.28s ease, width 0.28s ease;
}
.form__group select,
.form__group input,
.form__group textarea {
    display: block;
    width: 100%; padding: 0.125rem 0.125rem 0.0625rem;
    font-family: inherit; font-size: 1em; font-weight: 300; line-height: 22px; color: transparent;
    border-width: 0; border-color: transparent; background: none; box-shadow: none;
    transition: color 0.28s ease, border-color 0.28s ease;
    &:-internal-autofill-selected,
    &:-webkit-autofill{
        -webkit-box-shadow: 0 0 0 30px transparent inset !important;
        -webkit-text-fill-color: #000 !important;
    }
}
.postalCode .form__group input,
.postalCode .form__group textarea {
    font-size: 14px; line-height: 41px;
}
.form__group input[type="file"] {line-height: 1;}
.form__group input[type="file"] ~ .form__group__bar {display: none;}
.form__group select,
.form__group input:focus,
.form__group input:valid:not(:empty),
.form__group input.forceValid,
.form__group input.form__file,
.form__group input.has-value,
.form__group textarea:focus,
.form__group textarea:valid:not(:empty),
.form__group textarea.form__file,
.form__group textarea.has-value {
    color: #003259;
}
.form__group select ~ .form__group__label,
.form__group input:focus ~ .form__group__label,
.form__group input:valid:not(:empty) ~ .form__group__label,
.form__group input.forceValid ~ .form__group__label,
.form__group input.form__file ~ .form__group__label,
.form__group input.has-value ~ .form__group__label,
.form__group textarea:focus ~ .form__group__label,
.form__group textarea:valid:not(:empty) ~ .form__group__label,
.form__group textarea.form__file ~ .form__group__label,
.form__group textarea.has-value ~ .form__group__label {
    font-size: 0.8rem;
    color: #979797;
    top: -1.5rem;
    left: 0;
}
.form__group select:focus,
.form__group input:focus,
.form__group textarea:focus {
    outline: none;
}
.form__group select:focus ~ .form__group__label,
.form__group input:focus ~ .form__group__label,
.form__group textarea:focus ~ .form__group__label {
    color: #003259;
}
.form__group select:focus ~ .form__group__bar::before,
.form__group input:focus ~ .form__group__bar::before,
.form__group textarea:focus ~ .form__group__bar::before {
    left: 0;
    width: 100%;
}
.checkbox label,
.form__radio label {
    position: relative;
    display: block; padding-left: 2rem;
    text-align: left; color: #333;
    cursor: pointer;
}
.checkbox input,
.form__radio input {
    position: absolute; left: 0;
    width: auto;
    opacity: 0.00000001;
}
.radio {margin-bottom: 1rem;}
.radio .helper {
    position: absolute; top: -0.25rem; left: -0.25rem;
    display: block;
    font-size: 1rem; color: #979797;
    cursor: pointer;
    user-select: none;
}
.radio .helper::before, .radio .helper::after {
    content: '';
    position: absolute; top: 0; left: 0;
    width: 1rem; height: 1rem; margin: 0.25rem;
    -webkit-transition: -webkit-transform 0.28s ease;
    transition: -webkit-transform 0.28s ease;
    transition: transform 0.28s ease;
    transition: transform 0.28s ease, -webkit-transform 0.28s ease;
    border-radius: 50%;
    border: 0.125rem solid currentColor;
}
.radio .helper::after {
    transform: scale(0);
    background-color: #003259;
    border-color: #003259;
}
.radio label:hover .helper {color: #003259;}
.radio input:checked ~ .helper::after {
    transform: scale(0.5);
}
.radio input:checked ~ .helper::before {color: #003259;}
.checkbox {
    margin-top: 3rem; margin-bottom: 1rem;
}
.checkbox .helper {
    z-index: 0; position: absolute; top: 0; left: 0;
    width: 1rem; height: 1rem;
    color: #979797;
    border: 0.125rem solid currentColor; border-radius: 0.0625rem;
    transition: border-color 0.28s ease;
}
.checkbox .helper::before, .checkbox .helper::after {
    content: '';
    position: absolute;
    display: block; width: 0.2rem; height: 0;
    background-color: #003259;
    border-radius: 0.25rem;
    transform-origin: left top;
    transition: opacity 0.28s ease, height 0s linear 0.28s;
    opacity: 0;
}
.checkbox .helper::before {
    top: 0.65rem; left: 0.38rem;
    transform: rotate(-135deg);
    box-shadow: 0 0 0 0.0625rem $white;
}
.checkbox .helper::after {
    top: 0.3rem; left: 0;
    transform: rotate(-45deg);
}
.checkbox label:hover .helper {
    color: #003259;
}
.checkbox input:checked ~ .helper {
    color: #003259;
}
.checkbox input:checked ~ .helper::after, .checkbox input:checked ~ .helper::before {
    opacity: 1;
    transition: height 0.28s ease;
}
.checkbox input:checked ~ .helper::after {
    height: 0.5rem;
}
.checkbox input:checked ~ .helper::before {
    height: 1.2rem;
    transition-delay: 0.28s;
}
.radio + .radio,
.checkbox + .checkbox {
    margin-top: 1rem;
}

.has-error .legend.legend,
.gfield_error .legend.legend,
.gfield_error.form__group .form__group__label,
.has-error.form__group .form__group__label {color: #d9534f;}

.gfield_error.form__group .form__help,
.gfield_error.form__group .helper,
.has-error.form__group .form__help,
.has-error.form__group .helper,
.has-error.checkbox .form__help,
.has-error.checkbox .helper,
.has-error.radio .form__help,
.has-error.radio .helper,
.has-error.form__radio .form__help,
.has-error.form__radio .helper {color: #d9534f;}
.gfield_error .form__group__bar::before,
.has-error .form__group__bar::before {background: #d9534f; left: 0; width: 100%;}
