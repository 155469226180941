.B16LinkedArticles {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}
.B16LinkedArticles__container {

}
.B16LinkedArticles__title {
    margin-bottom: 30px;
}
.B16LinkedArticles__list {
    display: flex; flex-direction: column; gap: 30px;
}
.B16LinkedArticles__item {

}
.B16LinkedArticles__item-title {
    margin-bottom: 10px;
}
.B16LinkedArticles__item-description {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.B16LinkedArticles__item-link {
    display: inline-flex; align-items: center;
    color: $blue-dark;
    // color: $cyan;
    cursor: pointer;
    span {padding-bottom: 2px;}
    &:hover span {text-decoration: underline;}
    svg {margin-right: 10px;}
}