.B09PictoHighlight {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}
.B09PictoHighlight__title {
    margin-bottom: 30px;
    text-align: center;
}
.B09PictoHighlight__container {
    display: flex; gap: 30px;
    @include tablet-sm {flex-direction: column;}
}
.B09PictoHighlight__item {
    flex: 1; display: flex; flex-direction: column; align-items: center;
    max-width: 50%; padding: 0 30px;
    border-radius: 30px;
    &--grey-light {
        background-color: $grey-light;
        padding: 30px;
    }
    @include tablet-sm {max-width: 100%;}
}
.B09PictoHighlight__item-icon_custom,
.B09PictoHighlight__item-icon {
    margin: 30px; width: 100px; height: 100px;
}
.B09PictoHighlight__item-icon_custom img{object-fit: contain;}
.B09PictoHighlight__item-title {
    margin-bottom: 12px; text-align: center;
}
.B09PictoHighlight__item-description {
    font-size: 16px; line-height: 24px; text-align: center;

    a {
        // color: $cyan;
        &:hover {text-decoration: underline;}
    }
}
.B09PictoHighlight__item-cta {
    font-size: 16px; line-height: 24px; text-align: center;
    a {
        // color: $cyan;
        &:hover {text-decoration: underline;}
    }
}