.B14ReadAlso {
    margin: 40px 0;
    &:first-child {padding-top: 40px;}
}
.B14ReadAlso__container {
    display: flex;
    width: 100%; padding: 30px 0;
    border: 0 solid $blue-dark; border-width: 2px 0;
    @include tablet-sm {flex-direction: column;}
}

.B14ReadAlso__title {
    padding: 0 10px 10px 0;
    font-size: 20px;
}
.B14ReadAlso__list {
    
}
.B14ReadAlso__list-item {
    display: flex; align-items: center;
    .icon {margin-right: 10px; color: $cyan;}
}
.B14ReadAlso__link {
    color: $grey-dark;
    &:hover {text-decoration: underline;}
}