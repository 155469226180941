.S02WhitePaper {
    display: flex; flex-direction: column; align-items: center;
    padding: 25px 0;
    @include tablet {display: none;}
}
.S02WhitePaper__image {
    margin-bottom: 5px;
}
.S02WhitePaper__title {

}
.S02WhitePaper__button {
    margin-top: 12px; padding: 7px 15px 8px;
    font-family: 'Poppins', 'Lato';
}