.S05HubspotButton {
    a, button {
        position: relative; z-index: 1;
        display: inline-flex; justify-content: center; align-items: center;
        width: 100%; min-width: 140px; min-height: 50px; padding: 7px 15px 8px;
        text-align: center; color: $white; font-weight: bold; font-family: 'Poppins', 'Lato';
        background-color: transparent; border: none; border-radius: 5px; box-shadow: 0 2px 8px transparent;
        overflow: hidden; transition: 0.4s;
        &:hover {
            //background-color: $blue-dark; box-shadow: 0 0 0 2px inset $blue-dark;
            color: $white; box-shadow: 0 2px 12px -2px $cyan;
        }
        &::before {
            content: "";
            position: absolute; left: 0; top: 0; z-index: -2;
            width: 100%; height: 100%;
            background-color: $cyan; border-radius: 5px;
        }
        &::after {
            content: "";
            position: absolute; right: 100%; top: 0; z-index: -1;
            width: 100%; height: 100%;
            background-color: $blue-dark; border-radius: 5px;
            transition: 0.3s;
        }
        &:hover::after {transform: translateX(100%);}

    }
    @include tablet {
        & ~ & {display: none;}
        .S06Cta ~ & {display: none;}
    }

    .SbBtn + & a, .SbBtn + & button {
        border: 2px solid $cyan; background-color: $white; color: $cyan;
        &::before {background-color: $white;}
    }
    .SbBtn + .SbBtn + & a, .SbBtn + .SbBtn + & button {
        border: none; background-color: $cyan; color: $white;
        &::before {background-color: $cyan;}
    }
    .SbBtn + .SbBtn + .SbBtn + & a, .SbBtn + .SbBtn + .SbBtn + & button {
        border: 2px solid $cyan; background-color: $white; color: $cyan;
        &::before {background-color: $white;}
    }
    .SbBtn + .SbBtn + .SbBtn + .SbBtn + & a, .SbBtn + .SbBtn + .SbBtn + .SbBtn + & button {
        border: none; background-color: $cyan; color: $white;
        &::before {background-color: $cyan;}
    }
}