.S08PageListCat {

    @include tablet {display: none;}
}
.S08PageListCat__title {
    margin-bottom: 15px;
    font-size: 18px;
}

.S08PageListCat__list {
    display: flex; flex-direction: column; align-items: flex-start;
}
.S08PageListCat__list__children {
    display: flex; flex-direction: column; align-items: flex-start;
    padding-left: 10px; margin-top: -5px;
}
.S08PageListCat__item {
    display: inline-flex; align-items: center;
    margin-bottom: 10px;
    font-size: 12px; color: #5f5f5f;
    &:hover {color: $gold;}
    &.is-active {color: $blue-dark; font-weight: 700;}
    .icon {
        width: 6px; margin-right: 5px; 
        color: $gold;
    }
}