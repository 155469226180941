/*
<label for="modalID">open my modal</label>
{# MODAL #}
<input id="modalID" type="checkbox" class="modalNoJS__checkboxOpener">
<div class="modalNoJS">
    <label for="modalID" class="modalNoJS__backdrop"></label>
    <div class="modalNoJS__container modalNoJS__container--sm">
        
        <label for="modalID" class="modalNoJS__close">
            <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
        </label>
        
        MODAL CONTENT
        
    </div>
</div><!-- modal -->
{# MODAL #}
*/

.modalNoJS {
	opacity: 0;
	position: fixed; z-index: 10100;
	top: 0; left: -9999px; right: 0; bottom: 0;

    display: flex; justify-content: center; align-items: center;
	width: 100%; height: 100%;

	transition: left 0s ease-out .25s, opacity .2s ease-out;
}

.modalNoJS__checkboxOpener {
	position: fixed;
	left: -9999px;
}
.modalNoJS__checkboxOpener:checked + .modalNoJS {
	left: 0;
	opacity: 1;
	transition: left 0s ease-out, opacity .2s ease-out;
}

.modalNoJS__backdrop {
	position: absolute; z-index: 10110;
	top: 0; left: 0; right: 0; bottom: 0;
	width: 100%; height: 100%;
	background-color: $black;
	opacity: .5;
}

.modalNoJS__container {
	position: absolute; z-index: 10120;
	width: 650px; max-width: 96%; max-height: 96vh; overflow: auto;
	background-color: $white;
	padding: 30px 40px 50px;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, .05);
}
.modalNoJS__container--sm{width: 495px; padding-right: 60px; padding-left: 60px;}

.modalNoJS__close {
	position: absolute;
	top: 27px; right: 25px;
	font-size: 15px;
	cursor: pointer;
	transition: color .2s ease-out;

	&:hover {
		color: red;
	}
}

@include mobile{
	.modalNoJS__container,
	.modalNoJS__container--sm{padding-right: 30px; padding-left: 30px; padding-bottom: 30px;}
}
@include mobile{
	.modalNoJS__container,
	.modalNoJS__container--sm{padding-right: 20px; padding-left: 20px; padding-bottom: 30px;}
}