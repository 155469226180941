.scrollTop {
    position: fixed; right: 0; bottom: 15%; z-index: 5;
    width: 50px; height: 50px;
    background: rgba($blue-dark, .11);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover .scrollTop__svg {
        fill: $gold;
    }
}

.scrollTop__svg {
    width: 25px; height: 25px;
    fill: $white;
    transition: fill .2s;
}

@include tablet {
    .scrollTop {
        bottom: 5%;
    }
}