.pagination {
    display: flex; justify-content: center; align-items: center;
    padding: 0 0 40px;
}
.pagination__prev, .pagination__next, .pagination__page {
    display: inline-flex; justify-content: center; align-items: center;
    width: 40px; height: 40px; margin: 0 6px;
    border-radius: 10px; background-color: $grey-light;
    color: $grey-medium-light; font-size: 20px; font-weight: 700;
    transition: 0.4s; cursor: pointer;
    &:hover {
        color: $blue-dark;
    }
    &.is-active {
        background-color: $blue-dark; box-shadow: 0 5px 10px rgba(124, 144, 159, 0.31);
        color: $white;
    }
    &.is-inactive {
        pointer-events: none; color: #e0e0e0;
    }
}
.pagination__prev {
    margin: 0 17px;
    .icon {width: 10px;}
}
.pagination__next {
    margin: 0 17px;
    .icon {width: 10px;}
}
.pagination__list {
    display: flex; justify-content: center; align-items: center;
}
.pagination__page {

    
}