.S01Summary {
    margin-bottom: 30px;
    @include tablet {
        position: fixed; left: 0; top: 0;
        margin: 0; width: 100%; height: 100%;
        pointer-events: none;
        overflow: hidden;
    }
}
.S01Summary__toggle {
    position: fixed; right: 120px; top: 14px; z-index: 1;
    display: none; justify-content: center; align-items: center;
    min-height: 42px; padding: 5px 15px;
    border-radius: 5px; border: none;
    background-color: $blue-dark;
    color: $white; font-size: 18px; font-weight: 700; text-align: center;
    cursor: pointer; pointer-events: all;
    .admin-bar & {top: 46px}
    @include tablet {display: flex;}
    @include tablet-sm {
        .admin-bar & {top: 60px}
    }
    @include mobile {
        right: 100px;
    }
    @include mobile-sm {
        top: 18px; right: 80px;
        min-height: 35px; padding: 5px 10px;
        font-size: 14px;
        .admin-bar & {top: 63px}
    }
}
.S01Summary__container {
    @include tablet {
        position: absolute; right: 0; top: 0; z-index: 2;
        width: 100%; height: 100%; padding: 40px 20px;
        background-color: $white;
        pointer-events: all;
        transform: translateX(100%); transition: 0.4s;
        .admin-bar & {padding-top: 72px;}
        .S01Summary.is-active & {transform: translateX(0);}
    }
}
.S01Summary__logo {
    display: none; margin: 0 auto 35px auto; width: 112px;
    @include tablet {display: block;}
}
.S01Summary__close {
    display: none;
    position: absolute; right: 20px; top: 20px;
    padding: 5px;
    cursor: pointer;
    .admin-bar & {top: 52px}
    @include tablet {display: block;}
    @include tablet-sm {
        .admin-bar & {top: 66px}
    }
}
.S01Summary__title {
    margin-bottom: 12px;
    font-size: 18px;
}
.S01Summary__content {
    pointer-events: all;
}
.S01Summary__item {
    position: relative;
    display: flex; align-items: center;
    min-height: 30px; padding: 4px 10px; margin-left: -10px;
    font-size: 14px; color: #727272;
    border-radius: 5px;
    transition: 0.2s;
    overflow: hidden;
    p {position: relative; z-index: 2; margin: 0; padding-bottom: 1px;}

    &:hover {color: $blue-dark;}
    &.is-active {
        background-color: rgba(203, 176, 92, 0.18);
        color: $blue-dark; font-weight: 600;
    }
}

.S01Summary__item__progress {
    display: none;
    position: absolute; left: 0; top: 0; z-index: 1;
    width: 100%; height: 100%;
    background-color: $gold;
    transform-origin: left; transform: scaleX(0);
    transition: 0.1s;
    .S01Summary__item.is-active & {display: block;}
}