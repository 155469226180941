.B07Faq {
    &--grey-light {background-color: $grey-light;}
}
.B07Faq__container {
    padding: 50px 0;
    display: flex; gap: 40px;
    @include tablet-sm {
        flex-direction: column; align-items: center;
    }
}
.B07Faq__image {
    flex: 1; display: flex; justify-content: center; align-items: center;
}
.B07Faq__list {
    flex: 1;
}
.B07Faq__title {
    margin-bottom: 45px;
}
.B07Faq__item {
    margin-bottom: 10px;
}
.B07Faq__question {
    display: flex;
    padding-bottom: 10px;
    text-align: left;
    cursor: pointer; transition: 0.15s;
    span {padding-top: 2px;}
    svg {
        flex-shrink: 0;
        width: 30px; height: 30px;
        margin-right: 12px;
    }
    &:hover {
        text-decoration: underline;
        // .icon{color: $gold;}
    }
}
.B07Faq__question-icon-close {
    .B07Faq__item.is-active & {display: none;}
}
.B07Faq__question-icon-open {
    display: none;
    .B07Faq__item.is-active & {display: block;}
}
.B07Faq__answer {
    padding-bottom: 15px;
}
.B07Faq__answer-link {
    display: inline-flex; align-items: center;
    margin-top: 4px;
    color: $blue-dark;
    svg {width: 17px; height: 17px; margin-right: 12px;}
    &:hover span {text-decoration: underline;}
}

.B07Faq__cta {
    margin-top: 20px;
}