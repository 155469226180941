.B03TitleRating {
    background-color: $grey-light;
}
.B03TitleRating__container {
    display: flex; justify-content: space-between; align-items: center;
    padding: 50px 0;
    @include tablet-sm {flex-direction: column;}
}
.B03TitleRating__title {
    padding-right: 80px;
    flex-grow: 1;
    @include tablet-sm {padding: 0;}
}
.B03TitleRating__rating {

    @include tablet-sm {margin-top: 40px;}
}