.B08Form {
    background-color: $blue-dark;
    color: $white;
}
.B08Form__container {
    display: flex;  align-items: center; gap: 30px;
    @include tablet-sm {flex-direction: column;}
}
.B08Form__content {
    flex: 1;
    padding: 60px 0;
    @include tablet-sm {padding-bottom: 0;}
}
.B08Form__title {
    margin-bottom: 40px;
    color: $white;
}
.B08Form__description {
    font-size: 16px;

    ul {
        padding-left: 10px;
        li {
            position: relative;
            padding-left: 26px; margin-bottom: 15px;
            line-height: 22px;
            list-style-type: none;
            &:before {
                content: "";
                position: absolute; left: 0; top: 2px;
                display: flex; justify-content: center; align-items: center;
                width: 20px; height: 20px; padding-bottom: 3px;
                background-color: transparent;
                background-image: url("../../../assets/icomoon/SVG/arrow-right.svg"); 
                background-size: 50%; background-position: center; background-repeat: no-repeat;
                border: 2px solid $black; border-radius: 12px; 
                text-align: center; color: $white; font-size: 22px;
                filter: invert(1);
            }
            &:after {
                display: none;
            }
        }
    }
}
.B08Form__catchphrase {
    margin-top: 30px;
    font-size: 18px; font-weight: 700;
}
.B08Form__form {
    flex: 1;
    position: relative;
    padding: 60px 0;
    @include tablet-sm {width: 100%;}
}
.B08Form__form-wrapper {
    position: relative; z-index: 2;
    label {color: $white;}
}
.B08Form__form-bg {
    position: absolute; z-index: 1; right: 0; top: -10px;
    height: calc(100% + 20px); width: 100%;
    opacity: 0.16;
}