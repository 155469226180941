.wysiwyg {
    b, strong, em {font-weight: 600}
    i {font-style: italic;}
    a {
        color: $blue-dark;
        text-decoration: underline;
        &:hover {text-decoration: none;}
    }
    ul,
    ol {
        margin-top: 1em;
        margin-bottom: 1em;
        padding-left: 24px;
        // li+li{margin-top: 1em;}
        &:hover {text-decoration: none;}
    }
    ul {
        padding-left: 5px;
    }
    ul li {
        position: relative;
        padding-left: 1.5em;

        &:before {
            content: "";
            background-image: url("../../images/list-disc.svg");
            background-size: 12px 12px;
            position: absolute;
            top: 7px; left: 0;
            width: 12px; height: 12px;
        }
    }
    ol li {list-style-type: decimal;}
    blockquote, q {
        padding: 1em 2em; margin: 1em 0;
        position: relative; color: rgba(0,0,0,0.7);
        background-color: rgba(215, 187, 163, 0.15);
        &::before {content: "«"}
        &::after {content: "»"}
        p {display: inline;}
    }

    h1, h2, h3, h4 {
        margin: 20px 0;
        color: $blue-dark; font-weight: 700; font-family: 'Poppins', 'Lato', sans-serif;
    }
    h1 {
        font-size: 40px;
    }
    
    h2 {
        font-size: 26px;
    }
    
    h3 {
        font-size: 20px;
    }
    
    h4 {
        font-size: 18px;
    }
    p {
        margin-bottom: 10px;
    }

    table {
        margin: 12px 0; max-width: 100%; height: auto !important;
        box-shadow: 0 0 0 1px inset rgb(0 0 0 / 25%);
        overflow: auto;
        // thead {
        //     height: auto;
        //     background-color: $blue-dark;
        //     color: $white;
        //     font-weight: bold;
        // }
        // thead td {border-bottom: 0;}
        tr:first-child {
            height: auto;
            background-color: $blue-dark;
            color: $white;
            font-weight: bold;
        }
        tr:first-child td {border-bottom: 0;}
        tbody {
            height: auto;
        }
        tr {}
        th {
            border: 1px solid $blue-dark-2;
        }
        td {
            padding: 7px 10px;
            border: 1px solid $blue-dark-2;
        }
    }
}