.B17Dispatch {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}

.B17Dispatch__title {
    margin-bottom: 45px;
}
.B17Dispatch__list {}

.B17DispatchCard {
    display: flex;
    &+&{margin-top: 30px;}
    @include tablet-sm {
        flex-direction: column;
    }
}
.B17DispatchCard__image {
    flex-shrink: 0;
    display: block; width: 30%; max-width: 260px;
    border-radius: 30px 0 0 30px;
    overflow: hidden;
    .B17DispatchCard__image-picture {
        width: 100%; height: 100%;
    }
    img {
        width: 100%; height: 100%;
        object-fit: cover;
    }
    @include tablet-sm {
        width: 100%; height: 220px; max-width: 100%;
        border-radius: 30px 30px 0 0;
    }
}
.B17DispatchCard__content {
    flex-grow: 1;
    display: flex; flex-direction: column; align-items: flex-start;
    padding: 20px 25px 20px 30px;
    border-radius: 0 30px 30px 0;
    background-color: $grey-light;
    @include tablet-sm {
        padding: 20px 25px;
        border-radius: 0 0 30px 30px;
    }
}
.B17DispatchCard__title {
    margin-bottom: 10px;
    &:hover {
        text-decoration: underline;
        // color: $gold;
    }
}
.B17DispatchCard__description {
    margin-bottom: 10px;
}
.B17DispatchCard__link {
    display: inline-flex; align-items: center;
    color:  $blue-dark;
    &:hover span {text-decoration: underline;}
    span {padding-bottom: 2px;}
    svg {margin-right: 10px;}
}