$mq-tablet : 1100px !default;
$mq-tablet-sm : 700px !default;
$mq-mobile : 500px !default;
$mq-mobile-sm : 350px !default;

@mixin tablet {
    @media (max-width : $mq-tablet) {
        @content;
    }
}

@mixin tablet-sm {
    @media (max-width : $mq-tablet-sm) {
        @content;
    }
}

@mixin mobile {
    @media (max-width : $mq-mobile) {
        @content;
    }
}

@mixin mobile-sm {
    @media (max-width : $mq-mobile-sm) {
        @content;
    }
}

.show-t{display: none;}
@include tablet{
    .hide-t{display: none;}
    .show-t{display: block;}
}
.show-ts{display: none;}
@include tablet-sm{
    .hide-ts{display: none;}
    .show-ts{display: block;}
}
.show-m{display: none;}
@include mobile{
    .hide-m{display: none;}
    .show-m{display: block;}
}
.show-ms{display: none;}
@include mobile-sm{
    .hide-ms{display: none;}
    .show-ms{display: block;}
}