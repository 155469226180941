.postList__description {
    padding: 30px 0;
    text-align: center;
}
.postList {
    display: flex; flex-wrap: wrap; gap: 30px;
    padding: 70px 0 40px;
}

.postCard {
    display: flex; flex-direction: column;
    height: 100%; width: calc(50% - 30px / 2);
    @include tablet-sm {width: 100%;}
}
.postCard__image {
    position: relative;
    display: block; padding-top: 56%; width: 100%;
    border-radius: 30px 30px 0 0; overflow: hidden;

    img {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}
.postCard__content {
    flex: 1;
    padding: 25px 30px 30px;
    border-radius: 0 0 30px 30px; background-color: $grey-light;
}
.postCard__title {
    margin-bottom: 10px;
}
.postCard__infos {
    margin-bottom: 10px;
    color: $grey-dark;
}
.postCard__description {
    margin-bottom: 10px;
    color: $grey-dark;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.postCard__link {
    display: inline-flex; align-items: center;
    color: $blue-dark;
    cursor: pointer;
    svg {margin-right: 10px;}
    span {padding-bottom: 2px;}
    &:hover span {text-decoration: underline;}
}


// Search
.searchForm {
    display: flex; align-items: center; justify-content: center;
    width: 100%; height: 45px; margin: 50px 0 30px;
}
.searchForm__label {
    margin-right: 15px;
    font-size: 16px; font-weight: 700; color: $blue-dark;
}
.searchForm__input {
    flex-grow: 1;
    height: 100%; padding: 8px 10px 10px 10px;
    border-radius: 5px 0 0 5px; background-color: $grey-light; border: none;
    &::placeholder {color: #5f5f5f; font-size: 14px; font-style: normal;}
}
.searchForm__submit {
    display: flex; justify-content: center; align-items: center; flex-shrink: 0;
    width: 45px; height: 100%;
    background-color: $cyan; border-radius: 0 5px 5px 0; border: none;
    transition: 0.3s;
    &:hover {background-color: $blue-dark;}
    .icon {width: 25px; height: 25px; color: $white;}
}

.searchCount {
    margin: 20px 0 40px;
    text-align: center;
}
.searchResults {
    display: flex; flex-direction: column; gap: 30px;
    padding-bottom: 50px;
}
.searchCard {
    display: block; padding: 15px 30px;
    background-color: $grey-light; border-radius: 10px;
    color: $grey;
}
.searchCard__title {
    transition: 0.15s;
    .searchCard:hover & {color: $gold;}
}
.searchCard__intro {
    color: $grey-dark;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

// Question
.questionList {
    display: flex; flex-direction: column; gap: 30px;
    padding: 50px 0;
}
.questionCard {
    display: block; padding: 15px 30px;
    background-color: $grey-light; border-radius: 10px;
    color: $grey;
}
.questionCard__title {
    transition: 0.15s;
    .questionCard:hover & {
        // color: $gold;
        text-decoration: underline;
    }
}