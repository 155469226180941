.button {
    position: relative; z-index: 1;
    display: inline-flex; justify-content: center; align-items: center;
    min-width: 140px; min-height: 50px; padding: 10px 25px 12px;
    text-align: center; color: $blue-dark; font-weight: bold; font-family: 'Poppins', 'Lato';
    background-color: transparent; border: none; border-radius: 5px; box-shadow: 0 2px 8px transparent;
    overflow: hidden; transition: 0.4s;
    &:hover {
        //background-color: $blue-dark; box-shadow: 0 0 0 2px inset $blue-dark;
        color: $white; box-shadow: 0 2px 12px -2px $cyan;
    }
    &::before {
        content: "";
        position: absolute; left: 0; top: 0; z-index: -2;
        width: 100%; height: 100%;
        background-color: $cyan-light; border-radius: 5px;
    }
    &::after {
        content: "";
        position: absolute; right: 100%; top: 0; z-index: -1;
        width: 100%; height: 100%;
        background-color: $blue-dark; //border-radius: 5px;
        transition: 0.3s;
    }
    &:hover::after {transform: translateX(100%);}
}

.button--outline {
    background-color: transparent; 
    color: $blue-dark; box-shadow: 0 0 0 2px inset $blue-dark;
    transition: 0.2s;
    &::before, &::after {display: none;}
    &:hover {
        background-color: $blue-dark; box-shadow: 0 0 0 2px inset $blue-dark;
        color: $white;
        &::before, &::after {display: none;}
    }
}