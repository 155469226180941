.footer {
    position: relative; z-index: 3;
    padding: 37px 0 0;
    background-color: $blue-dark;
    color: $white;
    overflow: hidden;

    @include tablet {
        padding-bottom: 140px;
    }
    @include tablet-sm {
        padding-bottom: 125px;
    }
}

.footer__container {
    display: flex; gap: 30px;
    padding-bottom: 35px;
    position: relative;
    @include tablet-sm {flex-direction: column;}
}
.footer__col {
    flex: 1;
    display: flex; justify-content: flex-start; align-items: flex-start; flex-direction: column;
    @include tablet-sm {flex-direction: row;}
    @media(max-width: 640px) {flex-direction: column;}
}
.footer__infosMobile{flex-grow: 0;}
.footer__title {
    margin: 0 0 12px;
    font-size: 18px; line-height: 25px; font-weight: 700; font-family: 'Poppins', sans-serif;
}
.footer__image {
    flex-grow: 1;
    display: flex; justify-content: flex-start; align-items: flex-end;
    margin-top: 20px;
    @include tablet-sm {margin-top: 0; justify-content: flex-end; align-items: flex-start;}
}

.footer__menu + .footer__title{
    margin-top: 15px;
}

.footer__menu-item {
    display: flex; 
    margin: 0 0 7px 0;
    font-size: 13px;
    &:last-child {margin: 0;}

    svg {margin-right: 10px; margin-top: 3px;}
    p {opacity: 0.8;}
    a {
        opacity: 0.8; color: $white;
        cursor: pointer;
        &:hover {text-decoration: underline;}
    }
    &.footer__menu-item--submenu {flex-direction: column;}
    .icon {
        width: 16px; height: 16px; margin-right: 5px;
        color: $white;
    }
    &--sub {
        margin: -10px 0 5px 15px;
    }
}
.footer__menu-item-link {
    margin: 0;
    line-height: 20px;
    color: $white; cursor: pointer;
    &:hover {text-decoration: underline;}
}

.footer__socials {
    
}
.footer__socials-icon {
    margin-right: 16px;
    color: $white;
    transition: 0.1s;
    .icon {width: 42px; height: 42px;}
    &:hover {color: $gold;}
}

.footer__infos {
    display: flex; justify-content: space-between; align-items: center;
    min-height: 60px; padding: 10px 0;
    border-top: 1px solid $white;

    .footer__menu {
        display: flex; justify-content: flex-end; align-items: center;
        flex-wrap: wrap;
        @include tablet-sm {justify-content: center;}
    }
    .footer__menu-item {
        margin: 0 20px 0 0;
        &:last-child {margin: 0;}
        a, p {opacity: 0.65; font-size: 12px;}
        @include tablet-sm {
            margin: 2px 10px;
            &:last-child {margin: 2px 10px;}
        }
    }

    @include tablet-sm {flex-direction: column; padding-bottom: 50px;}
}
.footer__infos-content {
    display: flex; align-items: center;
    p {margin: 0 5px 0 15px;}
    @include tablet-sm {margin-bottom: 15px;}
}
.footer__infos-logo {
    width: 37px;
}

.footer__backgroundIcon {
    position: absolute;
    z-index: -1;
    right: -5%;
    bottom: -32%;
    width: 467px; height: 446px;
    color: $white;
    opacity: .16;
}

.footer__wpml{
    .wpml-ls-legacy-dropdown-click {
        width: auto;
        .wpml-ls-sub-menu{top: auto; bottom: 100%;}
    }
}