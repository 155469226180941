
.B04Citation {
    background-color: $blue-dark;
    color: $white;
}
.B04Citation__container {
    display: flex; gap: 40px; align-items: flex-end;
    padding-top: 60px;
    @include tablet-sm {
        flex-direction: column; align-items: center;
    }
}
.B04Citation__image {
    position: relative;
    flex: 0.44; display: flex; justify-content: center; align-items: flex-end;
}
.B04Citation__image-bg {
    position: absolute; z-index: 1; right: 2%; bottom: 10%;
    width: 112%; height: 105%;
    color: $white; opacity: 0.24;
}
.B04Citation__image-img {
    position: relative; z-index: 2;
    max-width: 450px;
}
.B04Citation__text {
    flex: 0.56; align-self: flex-start;
    padding: 0 0 65px;
    @include tablet-sm {
        width: 100%; padding: 0;
    }
}
.B04Citation__text-title {
    margin-bottom: 15px;
}
.B04Citation__text-wysiwyg {
    margin-bottom: 30px;

    a {color: $white;}
    ol {padding: 0;}
    ol li {
        position: relative;
        padding-left: 42px; margin-bottom: 15px;
        line-height: 22px;
        list-style-type: none;
        &::before {
            content: counter(list-item);
            position: absolute; left: 0; top: 0;
            display: flex; justify-content: center; align-items: center;
            width: 33px; height: 33px; padding-bottom: 1px;
            border: 1px solid $white; border-radius: 50%;
            text-align: center; color: $white; font-size: 22px;
        }
    }
    ul li {
        margin-bottom: 15px;
    }
    .icon {
        display: inline; margin-right: 10px; width: 40px; height: 40px;
    }
    p:nth-child(2) {
        display: inline;
    }
}
.B04Citation__text-cta {

}