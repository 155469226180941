.title {
    margin: 0;
    color: $blue-dark; font-weight: 700; font-family: 'Poppins', 'Lato', sans-serif;
}

.title--white {
    color: $white;
}

.title--1 {
    font-size: 40px;
    @include tablet-sm {font-size: 30px;}
}

.title--2 {
    font-size: 34px; //line-height: 46px;
    @include tablet-sm {font-size: 30px;}
}

.title--3 {
    font-size: 20px; //line-height: 27px;
}

.title--4 {
    font-size: 18px;
}