.B11Video {
    margin: 40px 0;
    &:first-child {padding-top: 40px;}
}
.B11Video__wrapper {
    width: 100%; margin: 0 auto; padding: 0 100px;
    @include tablet {
        max-width: 650px; padding: 0 0 20px;
    }
}
.B11Video__container {
    position: relative; width: 100%;
    padding-top: 56%;

    iframe {
        display: block;
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
    }
    @include tablet {
        margin-bottom: 30px;
    }
}
.B11Video__subToggle {
    position: absolute; left: 100%; bottom: 0;
    width: 40px; height: 40px;
    display: flex; justify-content: center; align-items: center;
    margin-left: 20px; padding: 5px 5px 5px 8px;
    border-radius: 50px; border: 2px solid $blue-dark; background-color: $white;
    color: $blue-dark; text-align: center;
    cursor: pointer; transition: 0.2s;
    .B11Video.has-subtitles &, &:hover {
        background-color: $blue-dark; color: $white;
    }
    /*.B11Video.has-subtitles &:hover {
        background-color: $white; color: $blue-dark;
    }*/
    @include tablet {
        left: 50%; bottom: -50px;
        margin: 0;
        transform: translateX(-50%);
    }
}
.B11Video__subToggle__svg {
    transition: all .2s;
}
.B11Video.has-subtitles .B11Video__subToggle__svg {
    transform: rotate(90deg);
}
.B11Video__subtitles {
    display: none;
    padding: 20px 30px;
    background-color: $grey-light; border-radius: 5px;
    color: $grey-dark;
    
}