.S09Search {
    margin-bottom: 30px;
    @include tablet {margin: 0;}
}
.S09Search__title {
    margin-bottom: 8px;
    font-size: 18px;
    @include tablet {display: none;}
}
.S09Search__form {
    display: flex;
    width: 100%; height: 45px;
}
.S09Search__input {
    height: 100%; width: calc(100% - 45px); padding: 8px 10px 10px 10px;
    border-radius: 5px 0 0 5px; background-color: $grey-light; border: 2px solid $grey-light;
    &::placeholder {color: #5f5f5f; font-size: 14px; font-style: normal;}
}
.S09Search__submit {
    display: flex; justify-content: center; align-items: center; flex-shrink: 0;
    width: 45px; height: 100%;
    background-color: $cyan; border-radius: 0 5px 5px 0; border: none;
    transition: 0.3s;
    &:hover {background-color: $blue-dark;}
    .icon {width: 25px; height: 25px; color: $white;}
}