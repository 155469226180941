.newsletter {
    padding: 50px 0 60px;
    background-color: $grey-light;
}

.newsletter__container {

}
.newsletter__title {
}
.newsletter__subtitle {
    margin-top: 10px;

}
.newsletter__form {
    margin-top: 25px;
}