.S03Score {
    padding: 7px 10px 10px;
    border-top: 1px solid $blue-dark;
    @include tablet {display: none;}

    .rmp-widgets-container.rmp-wp-plugin.rmp-main-container {margin: 0;}
    .rmp-rating-widget {
        text-align: left;
        color: $grey-dark;
        .rmp-heading {font-size: 14px;}
        .rmp-heading--title {margin: 0;}
        .rmp-heading--subtitle {font-weight: 700;}

        .rmp-icon--star {font-size: 32px;}


        .rmp-icon--ratings {
            color: #F0E8D0;
        }
        .rmp-icon--full-highlight {
            color: #cbb05c;
        }
        .rmp-icon--half-highlight {
            background: linear-gradient(to right,#cbb05c 50%,#F0E8D0 50%);
            -webkit-background-clip: text;
        }
        .rmp-icon--hovered {color: #cbb05c;}


        .rmp-rating-widget__results {
            font-size: 14px; color: $grey;
            .rmp-rating-widget__results__rating {
                font-size: 16px; font-weight: 700; color: $grey-dark;
            }
        }
        .rmp-rating-widget__not-rated {
            font-size: 14px;
        }
        .rmp-rating-widget__msg {
            font-size: 14px;
        }
    }

}
