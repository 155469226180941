.breadcrumb {
    
}
.breadcrumb__content {
    display: flex; justify-content: center; align-items: center; flex-wrap: wrap;
}
.breadcrumb__item {
    position: relative;
    padding-left: 12px;
    font-size: 14px; line-height: 17px;
    color: #272727; //opacity: 0.41; 
    &::before {content: "/"; position: relative; left: -6px;}
    &:first-child {
        padding: 0;
        &::before {display: none;}
    }

    .pageHero & {
        color: $white; opacity: 1;
        &::before {content: "|"}
    }

    a {
        color: #B9BDC8;
        &:hover {text-decoration: underline;}
    }
    
}