/***
** FONT WEIGHT
** - 100 Thin (Hairline)
** - 200 ExtraLight (UltraLight)
** - 300 Light
** - xxx Book (sometimes heavier than Regular)
** - 400 Regular / Normal
** - 500 Medium
** - 600 SemiBold (DemiBold)
** - 700 Bold
** - 800 ExtraBold (UltraBold)
** - 900 Black (Heavy)
***/

@mixin load-font($font-name, $font-file, $font-weight: 400, $font-style: normal){
  $svg-id: replace("${font-file}", "-", "", "g");

  font-family: '#{$font-name}';
  src: url('/app/themes/regieportage/resources/public/fonts/#{$font-file}.woff') format('woff'),
  url('/app/themes/regieportage/resources/public/fonts/#{$font-file}.woff2') format('woff2');
  // url('/app/themes/regieportage/resources/public/fonts/#{$font-file}.ttf') format('truetype');
  font-weight: $font-weight;
  font-style: $font-style;
  font-display: swap;
}

// OLD FONTS
    // @font-face {@include load-font('Poppins', 'Poppins-Regular', 400);}
    // // @font-face {@include load-font('Poppins', 'Poppins-Italic', 400, italic);}
    // @font-face {@include load-font('Poppins', 'Poppins-SemiBold', 600);}
    // // @font-face {@include load-font('Poppins', 'Poppins-SemiBoldItalic', 600, italic);}
    // @font-face {@include load-font('Poppins', 'Poppins-Bold', 700);}
    // // @font-face {@include load-font('Poppins', 'Poppins-BoldItalic', 700, italic);}
    // 
    // @font-face {@include load-font('Lato', 'Lato-Regular', 400);}
    // // @font-face {@include load-font('Lato', 'Lato-Italic', 400, italic);}
    // @font-face {@include load-font('Lato', 'Lato-Bold', 600);}
    // // @font-face {@include load-font('Lato', 'Lato-BoldItalic', 600, italic);}


// nouvelles fonts
    // chargé pour `.modal *{}` on peut p-e s'en passer ?
    // @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,800); 

    // chargé dans le simulateur pour `.modal .modal_button` on peut p-e s'en passer ?
    // @import url(https://fonts.googleapis.com/css?family=Raleway); 

    // chargé pour : (p-e pas necessaire ?)
    /*
        .simulateur .form_2 input[type=date],
        .simulateur .form_2 input[type=number],
        .simulateur .form_2 input[type=text],
        .simulateur .form_2 textarea

        .simulateur input[type=date].inline,
        .simulateur input[type=number].inline,
        .simulateur input[type=text].inline

    */
    // @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap); 

    @import url(https://fonts.googleapis.com/css?family=Lato:wght@400;600&display=swap);
    @import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap);