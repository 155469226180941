.B12GoogleReviews {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}
.B12GoogleReviews__container {

}
.B12GoogleReviews__title {
    margin-bottom: 20px;
    text-align: center;
}