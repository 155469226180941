body.login {
    padding-top: 0;
}

#login {
    padding-top: 5%;
}

// .login h1 a {display: none;}
.login h1 a {
    background-image: url("../../images/regie-portage-logo.svg");
    background-size: 120px;
    height: 90px;
    width: 120px;
}

#loginform:before {
    content: 'Connection';
    display: block;
    color: $blue;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}
.login form {
    border-radius: 10px;
}

.login label {
    display: block;
    color: $black;
    margin-bottom: 15px;
    font-size: 20px;
}

.login form .input, 
.login input[type="text"], 
.login input[type="password"] {

}

.login .forgetmenot label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 3px;
}
.login form .forgetmenot {
    float: none;
    text-align: center;
}
.login.wp-core-ui .button.button-large {

    float: none;
}

.login .button.wp-hide-pw {
    //min-width: 50px;
    //min-height: 50px;
    &::before, &::after {content: none;}
    background: none; border: none;
}

.login form input[type="checkbox"] {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}

#login form p.submit {
    text-align: center;
    margin-top: 10px;
}

.login input[type="checkbox"]:checked::before {
    margin: -3px 0 0 -5px;
    height: 21px;
    width: 21px;
}