.S07Share {
    position: relative;
    padding: 7px 10px 10px;
    border-top: 1px solid $blue-dark;
    @include tablet {
        display: flex; justify-content: space-between; align-items: center;
        padding: 0; margin-bottom: 0;
        border: none;
    }
}

.S07Share__title {
    color: $grey-dark; font-size: 14px;
}
.S07Share__links {
    display: flex; align-items: center; gap: 10px;
    @include tablet {gap: 20px;}
}
.S07Share__item {
    display: block;
    .icon {width: 34px; height: 34px;}
    .icon-facebook {color: #3c5998;}
    .icon-linkedin {color: #1277b5;}
    .icon-twitter {color: #1ca1f1;}
    .icon-email {color: #102248;;}
}