.B02KeyNumbers {
    margin: 50px 0;
    &:first-child {padding-top: 50px;}
}
.B02KeyNumbers__title {
    margin-bottom: 30px;
}

.B02KeyNumbers__container {
    display: flex; justify-content: center; flex-wrap: wrap; gap: 40px;
}

.B02KeyNumbers__item {
    position: relative;
    flex: 1;
    min-width: calc(33.33% - 40px * 2 / 3);
    text-align: center;
    &::before {
        display: none;
        content: ""; position: absolute; top: 50%; left: -20px;
        width: 1px; height: 100%;
        background-color: #dbddde;
        transform: translateY(-50%);
    }
    &::after {
        display: none;
        content: ""; position: absolute; top: -20px; left: 50%;
        width: 100%; height: 1px;
        background-color: #dbddde;
        transform: translateX(-50%);
    }

    @include tablet-sm {
        min-width: 100%;
    }

    .B02KeyNumbers--small & + &::before {
        display: block;
        @include tablet-sm {
            display: none;
        }
    }
    .B02KeyNumbers--small &::after {
        @include tablet-sm {
            display: block;
        }
    }

    .B02KeyNumbers--small.B02KeyNumbers--even & {
        &:nth-child(n+3)::after {display: block;}
    }
    .B02KeyNumbers--even & {
        min-width: calc(50% - 40px / 2);
        &:nth-child(odd)::before {display: none;}
        @include tablet-sm {
            min-width: 100%;
        }
    }
}
.B02KeyNumbers__item-number {
    font-size: 134px; line-height: 134px; color: $blue-dark; font-weight: 600;
    .B02KeyNumbers--small & {font-size: 48px; line-height: 48px;}
}
.B02KeyNumbers__item-title {
    font-size: 20px; color: $blue-dark; font-weight: 600;
    .B02KeyNumbers--small & {display: none;}
}
.B02KeyNumbers__item-description {
    margin-top: 20px;
}