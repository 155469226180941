.B15KeyPoints {
    margin: 40px 0;
    &:first-child {padding-top: 40px;}
}
.B15KeyPoints__container {
    width: 100%; padding: 30px;
    background-color: $grey-light; border-radius: 30px;
}
.B15KeyPoints__title {
    margin-bottom: 20px;
}

.B15KeyPoints__text {
    color: $grey-dark;
    ul li {
        position: relative;
        padding-left: 1em;

        &:before {
            content: "";
            background-image: url("../../images/list-disc.svg");
            background-size: 12px 12px;
            position: absolute;
            top: 7px; left: 0;
            width: 12px; height: 12px;
        }
    }
}