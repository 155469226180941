.T3ArticlesList {

}

.T3ArticlesList__container {
    display: flex; flex-wrap: wrap; gap: 30px;
}

.ArticleCard {
    display: flex; flex-direction: column;
    width: calc(50% - 30px/2);
}
.ArticleCard__image {
    position: relative;
    padding-top: 56%;
    border-radius: 30px 30px 0 0; overflow: hidden;
    img {
        position: absolute; left: 0; top: 0;
        width: 100%; height: 100%;
        object-fit: cover;
    }
}
.ArticleCard__content {
    flex: 1;
    padding: 25px 30px;
    background-color: $grey-light; border-radius: 0 0 30px 30px;
}
.ArticleCard__title {
    margin-bottom: 10px;
}
.ArticleCard__infos {
    margin-bottom: 10px;
    color: $grey-medium-light;
    white-space: nowrap; overflow: hidden; text-overflow: ellipsis;
}
.ArticleCard__description {
    margin-bottom: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.ArticleCard__link {
    display: inline-flex; align-items: center;
    color: $cyan;
    cursor: pointer;
    svg {margin-right: 10px;}
    span {padding-bottom: 2px;}
    &:hover span {text-decoration: underline;}
}